import { graphql } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import { WorksCategoryContentQuery } from "../../graphql-types";
import { Intro } from "../components/Intro";
import { Layout } from "../components/Layout";
import { ProductCategoriesNav } from "../components/products/ProductCategoriesNav";
import { ProductCategoryGallery } from "../components/products/ProductCategoryGallery";
import { Section } from "../components/section/Section";
import { useWorksCategories } from "../shared/use-works-categories";

export const query = graphql`
  query WorksCategoryContent($slug: String!) {
    datoCmsWorksCategory(slug: { eq: $slug }) {
      title
      images {
        thumb: gatsbyImageData(width: 500, placeholder: BLURRED)
        full: gatsbyImageData(layout: FULL_WIDTH)
        filename
        title
      }
    }
  }
`;

interface WorksCategoryPageProps {
  pageContext: {
    slug: string;
  };
  data: WorksCategoryContentQuery;
}

const WorksCategoryPage: React.FC<WorksCategoryPageProps> = (props) => {
  const category = props.data.datoCmsWorksCategory;
  const allCategories = useWorksCategories();

  return (
    <Layout>
      <Helmet>
        <title>{`${category!.title!} | Lavori realizzati`}</title>
      </Helmet>
      <Intro
        title="Lavori realizzati"
        tagLine="Una selezione dei lavori che abbiamo realizzato per i nostri clienti"
      ></Intro>
      <Section>
        <ProductCategoriesNav
          basePath="lavori"
          categories={allCategories}
        ></ProductCategoriesNav>
        <div>
          <ProductCategoryGallery
            images={category!.images!}
          ></ProductCategoryGallery>
        </div>
      </Section>
    </Layout>
  );
};

export default WorksCategoryPage;
